/* Hide Navbar on click */
$(document).click(function (event) {
  var clickover = $(event.target);
  var _opened = $(".navbar-collapse").hasClass("show");
  if (_opened === true && !clickover.hasClass("navbar-toggler")) {
    $(".navbar-toggler").click();
  }
});


/* Add header-fixed class when page is scrolled */
$(window).scroll(function () {
  var scroll = $(window).scrollTop();
  if (scroll >= 30) {
    $("header").addClass("header-fixed");
  } else {
    $("header").removeClass("header-fixed");
  }
});
