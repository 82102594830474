$('.form').on('submit', function (event) {
  event.preventDefault();
  event.stopPropagation();

  if ($(this)[0].checkValidity() === true) {
    $('.form-loading').show();

    $.post({
      url: '/',
      dataType: 'json',
      data: $(this).serialize(),
      success: function (response) {
        $('.form-loading').hide();
        if (response.success) {
          $('.form-success').show();
        } else {
          $('.form-error').show();
        }
      }
    });
  }
  $(this).addClass('was-validated');
});