if ($(".swiper-container").length) {
  var mySwiper = new Swiper('.swiper-container', {
    spaceBetween: 0,
    slidesPerView: 3,

    autoplay: {
      delay: 7000
    },

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },

    breakpoints: {
      991: {
        slidesPerView: 2
      },
      767: {
        slidesPerView: 1
      }
    }
  });

  mySwiper
    .autoplay
    .stop();
}
