/* Smooth Anchor Scroll */
$("a").on('click', function (event) {
  var hash = this.hash;
  //ignore if there is no hash or it is a toggler
  if (hash.length === 0 || $(this).attr('data-toggle') === 'collapse') {
    return;
  }

  event.preventDefault();

  $('html, body').animate({
    scrollTop: $(hash).offset().top
  }, 600, function () {
    window.location.hash = hash;
  });
});