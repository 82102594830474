/* Social Share */
$('body').on('click', '.share-facebook', function (e) {

  e.preventDefault();
  e.stopPropagation();

  var href = 'https://www.facebook.com/sharer/sharer.php';
  var url = url = window.location.href;

  var popUpUrl = href + "?u=" + url;
  return window.open(popUpUrl, 'Auf Facebook posten', 'width=670, height=602');
});

$('body').on('click', '.share-linkedin', function (e) {
  e.preventDefault();
  e.stopPropagation();

  var href = 'https://www.linkedin.com/cws/share';
  var url = url = window.location.href;

  var popUpUrl = href + "?url=" + url;
  return window.open(popUpUrl, 'Auf Linkedin posten', 'width=600, height=430');
});

$('body').on('click', '.share-xing', function (e) {
  e.preventDefault();
  e.stopPropagation();

  var href = 'https://www.xing.com/spi/shares/new';
  var url = url = window.location.href;

  var popUpUrl = href + "?url=" + url;
  return window.open(popUpUrl, 'Auf XING posten', 'width=500, height=750');
});

$('body').on('click', '.share-twitter', function (e) {
  e.preventDefault();
  e.stopPropagation();

  var href = 'https://twitter.com/share';
  var url = window.location.href;
  var text = '';
  var hashtags = '';
  var via = '';

  if ($(e.target).attr('data-via')) via = $(e.target).attr('data-via');
  if ($(e.target).attr('data-text')) text = $(e.target).attr('data-text');
  if ($(e.target).attr('data-hashtags')) hashtags = $(e.target).attr('data-hashtags');

  text = text ? "&text=" + text : '';
  hashtags = hashtags ? "&hashtags=" + encodeURIComponent(hashtags) : '';
  via = via ? "&via=" + encodeURIComponent(via) : '';

  var popUpUrl = href + "?url=" + url + text + hashtags + via;
  return window.open(popUpUrl, "Teile einen Link auf Twitter", "width=710, height=460");
});